<template>
  <div>
    <div class="platform-buttom-list">
      <div class="bottom-button-active">
        申请列表
      </div>
    </div>
    <div>
      <RadioGroup
        v-model="query.type"
        type="button"
        button-style="solid"
        size="small"
        class="m-b-10"
        @on-change="onChangeType"
      >
        <Radio
          v-for="item in typeArray"
          :key="'type_'+item.value"
          :label="item.value"
        >{{item.name}}</Radio>
      </RadioGroup>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col span="3">
          <DatePicker
            v-model="dates"
            type="daterange"
            placeholder="选择起止日期"
            size="small"
            transfer
            style="width: 100%"
            @on-change="onChangeDates"
          ></DatePicker>
        </i-col>
        <!-- <i-col span="3">
          <Select
            size="small"
            placeholder="经营方"
            v-model="query.companyId"
            transfer
            clearable
          >
            <i-option
              v-for="item in companyArray"
              :key="'company_' + item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </Select>
        </i-col> -->
        <i-col span="3">
          <Select
            size="small"
            placeholder="状态"
            v-model="query.status"
            transfer
            clearable
          >
            <i-option
              v-for="item in statusArray"
              :key="'status_' + item.value"
              :value="item.value"
            >
              {{ item.name }}
            </i-option>
          </Select>
        </i-col>
        <i-col span="4">
          <i-input
            v-model.trim="query.keyword"
            size="small"
            placeholder="关键字：编号"
          ></i-input>
        </i-col>
        <i-col span="6">
          <Button
            type="primary"
            icon="ios-search"
            size="small"
            class="m-r-5"
            @click="onSearch"
          >搜索</Button>
          <Button
            type="success"
            size="small"
            @click="onCreate"
          >创建施工申请</Button>
        </i-col>
      </Row>
      <div class="m-b-10">
        <Button
          type="success"
          size="small"
          :disabled="selectedItems.length===0"
          @click="onDownloadFile"
        >导出Excel</Button>
      </div>
      <div>
        <Table
          stripe
          max-height="620"
          size="small"
          :data="tableList"
          :columns="tableColumns"
          :loading="tableLoading"
          @on-selection-change="selectionChange"
        ></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="pagesizeSetting"
            @on-page-size-change='onChangePageSize'
            :current="query.pageNumber"
            @on-change="onChangePageNum"
          ></Page>
        </div>
      </div>
    </div>
    <task-create
      v-if="showCreate"
      ref="taskCreate"
      :typeArray="typeArray"
      :onSuccess="getTableData"
    />
  </div>
</template>

<script>
import { indexMixins } from '@/components/constructionApply/mixins'
import { downloadFileRequest } from '@/utils/download'

import { getWorkdayPage, removeWorkday } from '@/api/msp/workdayTable'
import TaskCreate from './TaskCreate.vue'
export default {
  mixins: [indexMixins],
  components: { TaskCreate },
  data () {
    return {
      typeArray: [
        { value: 1, name: '车站计划' },
        { value: 2, name: '车场计划' },
        { value: 3, name: '临时计划' }
      ],
      dates: [],
      companyArray: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        // companyId: undefined,
        type: 1,
        startDate: '',
        endDate: '',
        status: null,
        keyword: ''
      },
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '编号', align: 'center', minWidth: 120, key: 'code' },
        {
          title: '施工日期',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            return h('span', `${row.startDate} 至 ${row.endDate}`)
          }
        },
        { title: '任务发起方', align: 'center', minWidth: 120, key: 'companyName' },
        { title: '任务数量', align: 'center', minWidth: 100, key: 'taskCount' },
        { title: '申请人', align: 'center', minWidth: 120, key: 'userName' },
        { title: '创建时间', align: 'center', minWidth: 120, key: 'createTime' },
        { title: '状态', align: 'center', minWidth: 120, key: 'statusName' },
        {
          title: '操作',
          align: 'center',
          minWidth: 180,
          fixed: 'right',
          render: (h, { row }) => {
            const html = [
              h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.showDetail(row)
                  }
                }
              }, '详情')
            ]
            // if (row.status === 2) { // 申请中
            //   html.push(h('a', {
            //     style: {
            //       color: '#ef4f4f'
            //     },
            //     class: ['m-r-5'],
            //     on: {
            //       click: () => {
            //         this.onRevoke(row)
            //       }
            //     }
            //   }, '撤销'))
            // }
            if (row.status === 0) { // 草稿
              html.push(h('a', {
                style: {
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.onDel(row)
                  }
                }
              }, '删除'))
            }
            return h('div', html)
          }
        }
      ],
      showCreate: false,
      selectedItems: []
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    async getTableData () {
      this.tableLoading = true
      const result = await getWorkdayPage(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    onChangeType () {
      this.query.pageNumber = 1
      this.query.pageSize = 15
      this.query.keyword = ''
      this.query.companyId = undefined
      this.query.status = null
      this.getTableData()
    },
    onChangeDates (dates) {
      this.query.startDate = dates[0]
      this.query.endDate = dates[1]
    },
    onSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageSize = curPageSize
      this.query.pageNumber = 1
      this.getTableData()
    },
    selectionChange (selections) {
      this.selectedItems = selections.map(x => x.id)
    },
    onCreate () {
      this.showCreate = true
      this.$nextTick(() => {
        this.$refs.taskCreate.showModal()
      })
    },
    onDel (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除改项？',
        onOk: async () => {
          const postData = {
            tableId: this.type,
            workdayId: params.id
          }
          const result = await removeWorkday(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTableData()
          }
        }
      })
    },
    onRevoke (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要撤销？',
        onOk: async () => {
          // const postData = {
          //   tableId: this.type,
          //   workdayId: params.id
          // }
          // const result = await removeWorkday(postData)
          // if (result && result.success) {
          //   this.$Notice.success({ desc: '操作成功' })
          //   this.getTableData()
          // }
        }
      })
    },
    showDetail (obj) {
      this.$router.push({ name: 'construction-apply-detail', params: { id: obj.id } })
    },
    onDownloadFile () {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v1/workdayReport/exportOpCompanyWorkdayExcel', { tableIds: JSON.stringify(this.selectedItems) }, '施工申请明细')
    }
  }
}
</script>
